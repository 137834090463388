export const setSearchDeclaration = (payload) => {
  return {
    type: "SET_DATA_SEARCH_DECLARATION",
    payload,
  };
};

export const resetDeclaration = (payload) => {
  return {
    type: "RESET_DECLARATION",
    payload,
  };
};

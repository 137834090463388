import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Row,
  Table,
  Badge,
} from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";
import { Link, useParams } from "react-router-dom";
import "./Declaration.css";
import moment from "moment";
import "moment/locale/id";
import CurrencyFormat from "../../components/Formating/CurrencyFormat";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ClaimLink from "../../components/ClaimLink/ClaimLink";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
    box-shadow: #e0e0ec66 0px 0px 10px 10px;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 15px;
    color: #646464 !important;
  }

  .accordion-button {
    font-size: 18px;
    font-weight: 800;
    color: #646464;
  }

  .accordion-button:not(.collapsed) {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
`;

export default function DetailDeclaration() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const params = useParams();
  const [detail, setDetail] = useState();
  const navigate = useNavigate();

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${decode}`,
      callback: (res) => {
        setDetail(res.data);
        setLoading(false);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
        console.log(error);
      },
    });
  }, [decode, error]);

  const handleClaim = (id) => {
    const encode = window.btoa(id);
    navigate(`/claim/submission/${encode}`);
  };
  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <Row>
        <Col xs={12} md={8}>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Container
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Insured</p>
              <div className="insured">
                <h5 className="m-0">
                  {detail?.insuredName ? detail?.insuredName : "-"}
                </h5>
              </div>

              <div className="p-3">
                <p className="title">Conveyance</p>
                <p className="value">
                  {`${detail?.vesselName} - ${detail?.vesselDescription} - ${detail?.otherTransport}`}
                </p>

                <p className="title mt-5">Deductibles</p>
                <p className="value" style={{ whiteSpace: "pre-wrap" }}>
                  {detail?.policyDeductibles ? detail?.policyDeductibles : "-"}
                </p>
              </div>
            </Card.Body>
          </Card>

          <StyledAccordion className="">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Data Kontainer</Accordion.Header>
              <Accordion.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Nomor Kontainer</th>
                      <th>Job No</th>
                      <th>Seal No</th>
                      <th>Premium</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail?.attachment?.length > 0 ? (
                      <>
                        {detail?.attachment?.map((data, index) => (
                          <tr
                            key={index}
                            style={{ fontSize: "12px", color: "#646464" }}
                          >
                            <td className="text-center">{index + 1}</td>
                            <td>{data.containerNo}</td>
                            <td>{data.jobNo}</td>
                            <td>{data.sealNo}</td>
                            <td>{CurrencyFormat(data.premium)}</td>
                            <td className="text-center">
                              {data.isClaimed ? (
                                <Badge className="badge-table">
                                  <ClaimLink
                                    id={data?.claimContainerId}
                                    value="Claimed"
                                    detail
                                  />
                                </Badge>
                              ) : (
                                <>{detail?.statusName}</>
                              )}
                            </td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <>
                        <tr className="text-center">
                          <td
                            colSpan={6}
                            style={{ fontSize: "14px", color: "#646464" }}
                          >
                            Data container tidak ditemukan
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </Col>

        <Col xs={12} md={4}>
          {detail?.attachment
            ?.map((item) => item.isClaimed)
            .includes(false) && (
            <div className="claim-btn" onClick={() => handleClaim(decode)}>
              <p className="mb-0">Ajukan Klaim</p>
            </div>
          )}
          <h5 className="pl-4 pr-4 mt-2 title-header-right">
            Detail Sertifikat
          </h5>
          <Card className="card-wrapper p-4" style={{ minHeight: "350px" }}>
            <Card.Body className="main-card">
              <p className="title mb-0">No. Polis/No. Sertifikat</p>
              <p className="value-left">
                {detail?.policyNumber}/{detail?.certificateNumber}
              </p>
              <hr />

              <p className="title mb-0">Voyage</p>
              <p className="value-left">
                {detail?.portDeparture || detail?.portDestination
                  ? `From ${detail?.portDeparture} To ${detail?.portDestination}`
                  : "-"}
              </p>

              <p className="title mb-0">Kendaraan</p>
              <p className="value-left">
                {detail?.vesselName ? detail?.vesselName : "-"}
              </p>

              <p className="title mb-0">Tanggal Keberangkatan</p>
              <p className="value-left">
                {moment(detail?.estDepartureDate).format("DD MMM YYYY")}
              </p>

              <p className="title mb-0">Tanggal Terbit</p>
              <p className="value-left">
                {moment(detail?.policyIssueDate).format("DD MMM YYYY") ?? "-"}
              </p>
            </Card.Body>
          </Card>

          <Card
            className="px-3 py-4 mt-4 card-wrapper"
            style={{ minHeight: "auto" }}
          >
            <Card.Body className="main-card">
              <p className="title mb-0">Premi & Biaya Layanan</p>
              <p className="value-left">{CurrencyFormat(detail?.premium)}</p>

              <p className="title mb-0">Biaya Materai</p>
              <p className="value-left">{CurrencyFormat(detail?.stampDuty)}</p>

              <hr />
              <p className="title mb-0">Net Premium</p>
              <p className="value-left">
                {CurrencyFormat(detail?.netPremium ?? 0)}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col lg={12}>
          <Link to="/declaration" style={{ textDecoration: "none" }}>
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              BACK
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

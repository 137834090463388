import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import BannerImg from "./images/banner.svg";
import ListClaim from "./pages/Claim/ListClaim";
import Declaration from "./pages/Declaration/Declaration";
import DetailDeclaration from "./pages/Declaration/DetailDeclaration";
import Home from "./pages/Home/Home";
import NotFound from "./pages/NotFound/NotFound";
import DetailClaim from "./pages/Claim/DetailClaim";
import ClaimSubmission from "./pages/Claim/ClaimSubmission";
import ClaimSummary from "./pages/Claim/ClaimSummary";
import Success from "./pages/Claim/Success/Success";

function App() {
  const currentPath = window.location.pathname;

  return (
    <Router>
      <Navbar />

      {!currentPath.includes("404") && (
        <div className="container mt-5 mb-5">
          <div className="d-flex justify-content-center">
            <img src={BannerImg} alt="" className="w-100 mt-5" />
          </div>
        </div>
      )}

      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/declaration" element={<Declaration />}></Route>
        <Route
          exact
          path="/declaration/detail/:id"
          element={<DetailDeclaration />}
        ></Route>
        <Route exact path="/claim" element={<ListClaim />}></Route>
        <Route exact path="/claim/detail/:id" element={<DetailClaim />}></Route>
        <Route
          exact
          path="/claim/submission/:id"
          element={<ClaimSubmission />}
        ></Route>
        <Route
          exact
          path="/claim/submission/summary"
          element={<ClaimSummary />}
        ></Route>
        <Route
          exact
          path="/claim/submission/success"
          element={<Success />}
        ></Route>

        <Route
          exact
          path="*"
          element={
            <>
              <NotFound />
            </>
          }
        ></Route>
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;

import axios from "axios";
import constants from "../constants/constants";
import generateCorrelationId from "./generateCorrelationId";
import getCurrentDatetime from "./getCurrentDatetime";

const apiKey = process.env.REACT_APP_X_API_KEY;

export default function axiosPost({
  url,
  data,
  headers,
  callback,
  errorCallback,
  responseType,
}) {
  axios
    .post(url, data, {
      headers: {
        ...headers,
        "Content-Type": "Application/json",
        "X-Transmission-Date-Time": getCurrentDatetime(),
        "X-Correlation-Id": generateCorrelationId(),
        "X-Api-Key": apiKey,
      },
      responseType: responseType,
    })
    .then((res) => {
      if (res.data.statusCode === constants.HTTP_STATUS_SUCCESS) {
        callback(res.data);
      } else {
        errorCallback(res.data);
      }
    })
    .catch((err) => {
      errorCallback(err);
    });
}

import constants from "../constants/constants";
import dataURIToBlob from "./dataURIToBlob";
import generateCorrelationId from "./generateCorrelationId";
import getCurrentDatetime from "./getCurrentDatetime";

const uploadDocClaim = async (fileObject, refId, claimId, type, filename) => {
  const token =
    localStorage.getItem("token") ?? sessionStorage.getItem("token");
  const user = localStorage.getItem("user") ?? sessionStorage.getItem("user");

  if (fileObject !== null) {
    var data = new FormData();
    const fileName = filename?.split(".");
    const fileNameResult = fileName[fileName?.length - 1];

    const file = dataURIToBlob(fileObject);

    data.append("file", file, `.${fileNameResult}`);
    data.append("type", type);
    data.append("description", type);
    data.append("refId", refId);
    data.append("claimContainerId", claimId);
    await fetch(`${constants.DEFAULT_URL}/claim-container/upload`, {
      method: "POST",
      body: data,
      headers: {
        "X-Transmission-Date-Time": getCurrentDatetime(),
        "X-Correlation-Id": generateCorrelationId(),
        "X-Authorization": token,
        "X-User": user,
      },
    });
  }
  //   setLoading(false);
  // const encode = window.btoa(claimId);
  // navigate(`/claim/detail/${encode}`);
  window.scrollTo(0, 0);
};

export default uploadDocClaim;

const constants = {};

constants.DEFAULT_URL = process.env.REACT_APP_API_URL;

constants.HTTP_STATUS_SUCCESS = 200;
constants.HTTP_STATUS_UNAUTHORIZED = 403;
constants.HTTP_STATUS_FAILED = 401;
constants.HTTP_STATUS_ERROR = 400;

export default constants;

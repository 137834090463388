import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";
import { Link, useParams } from "react-router-dom";
import "./Claim.css";
import moment from "moment";
import "moment/locale/id";
import CurrencyFormat from "../../components/Formating/CurrencyFormat";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import StatusHistory from "../../components/StatusHistory.js/StatusHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import TableContainer from "../../components/DataTable/TableContainer";
import { resetClaim } from "../../store/claim/actions";
import { useDispatch } from "react-redux";

export default function DetailClaim() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const dispatch = useDispatch();

  const params = useParams();
  const [detail, setDetail] = useState();
  const [detailContainer, setDetailContainer] = useState([]);

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    setError(null);
    dispatch(resetClaim());
    axiosGet({
      url: `/claim-container/id/${decode}`,
      callback: (res) => {
        setDetail(res.data);
        setStatusHistory(res.data.claimContainerStatus);
        setDetailContainer(res.data.claimContainerDetail);
        setLoading(false);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
        console.log(error);
      },
    });
  }, [decode, error, dispatch]);

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <Row>
        <Col xs={12} md={8}>
          <div className="d-flex justify-content-between ">
            <div className="pl-5 pr-5">
              <span className="title-header-left">No Klaim</span>
              <p>{detail?.noClaim}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} md={4} className="">
          <h5 className="pl-4 pr-4 mt-2 title-header-right">
            Detail Sertifikat
          </h5>
        </Col>
        <Col xs={12} md={8}>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">Detail Klaim</Card.Header>
            <Card.Body className="main-card">
              <p className="title">Insured</p>
              <div className="insured">
                <h5 className="m-0">{detail?.insured ?? "-"}</h5>
              </div>

              <Row className="mt-4">
                <Col md={6}>
                  <p className="title">Tanggal Pengajuan</p>
                  <p className="value">
                    {moment(detail?.createdDAte).format("DD MMM YYYY") ?? "-"}
                  </p>

                  <p className="title">Nama PIC</p>
                  <p className="value">{detail?.claim?.picName ?? "-"}</p>
                </Col>
                <Col md={6}>
                  <p className="title">Email PIC</p>
                  <p className="value">{detail?.claim?.picEmail ?? "-"}</p>

                  <p className="title">No Handphone PIC</p>
                  <p className="value">
                    {detail?.claim?.picPhoneNumber ?? "-"}
                  </p>
                </Col>
              </Row>
              <hr />
              <div className="card-main-header mb-3">Detail Kontainer</div>
              <Row>
                <Col md={12}>
                  <TableContainer data={detailContainer} />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Kronologi
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Tanggal Kejadian</p>
              <p className="value">
                {moment(detail?.accidentDate).format("DD MMM YYYY") ?? "-"}
              </p>

              <p className="title">Lokasi Kejadian</p>
              <p className="value">{detail?.accidentLocation ?? "-"}</p>

              <p className="title">Detail Kronologi Kejadian</p>
              <p className="value">{detail?.accidentDetail ?? "-"}</p>
            </Card.Body>
          </Card>

          <Card
            className="card-wrapper p-4 mb-4"
            style={{ minHeight: "100px" }}
          >
            <Card.Header className="card-main-header">
              Daftar Lampiran
            </Card.Header>
            <Card.Body className="main-card">
              <DownloadDoc data={detail?.claimContainerDocument} />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <Card className="card-wrapper p-4" style={{ minHeight: "350px" }}>
            <Card.Body className="main-card">
              <p className="title mb-0">No. Polis/No. Sertifikat</p>
              <p className="value-left">
                {detail?.claim?.policyNumber}/{detail?.certificateNo ?? "-"}
              </p>
              <hr />

              <p className="title mb-0">Rute</p>
              <p className="value-left">{detail?.route}</p>

              <p className="title mb-0">Kendaraan</p>
              <p className="value-left">{detail?.conveyance ?? "-"}</p>

              <p className="title mb-0">Tanggal Keberangkatan</p>
              <p className="value-left">
                {moment(detail?.departDate).format("DD MMM YYYY")}
              </p>

              <p className="title mb-0">Tanggal Terbit</p>
              <p className="value-left">
                {moment(detail?.createdDate).format("DD MMM YYYY")}
              </p>
            </Card.Body>
          </Card>

          <Card
            className="px-3 py-4 mt-4 card-wrapper"
            style={{ minHeight: "auto" }}
          >
            <Card.Body className="main-card">
              <p className="title mb-0">Premi & Biaya Layanan</p>
              <p className="value-left">
                {CurrencyFormat(detail?.cargoContainer?.premium)}
              </p>

              <p className="title mb-0">Biaya Materai</p>
              <p className="value-left">
                {CurrencyFormat(detail?.cargoContainer?.stampDuty ?? 0)}
              </p>

              <p className="title mb-0">Net Premium</p>
              <p className="value-left">
                {CurrencyFormat(detail?.cargoContainer?.netPremium ?? 0)}
              </p>

              <hr />
              <p className="title mb-0">Estimasi Kerugian</p>
              <p className="value-left">
                {CurrencyFormat(detail?.claimAmount ?? 0)}
              </p>
            </Card.Body>
          </Card>

          {statusHistory && (
            <Row>
              <Col lg={12}>
                <StatusHistory statusHistory={statusHistory} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Link to="/claim" style={{ textDecoration: "none" }}>
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              BACK
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

const DownloadDoc = ({ data }) => {
  const [loadingDownload, setLoadingDownload] = useState(null);

  const downloadDoc = (fileId, type, originalName) => {
    setLoadingDownload(fileId);
    axiosGet({
      url: `/claim-container/download/file-id/${fileId}`,
      responseType: "blob",
      callback: (res) => {
        setLoadingDownload("");
      },
      errorCallback: (res) => {
        setLoadingDownload("");
        let file = new File(
          [res],
          `${type === "DOC_EIR" ? "DOC_EOR" : type}${originalName}`
        );
        saveAs(file);
      },
    });
  };

  return (
    <Fragment>
      {data?.length > 0 ? (
        <>
          {data?.map((item, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between">
                <div>
                  {item.type === "DOC_DOCUMENTATION"
                    ? "Lampiran Foto Dokumentasi"
                    : "Lampiran EOR"}
                </div>
                <div>
                  <Button
                    type="button"
                    className="download-doc"
                    onClick={() =>
                      downloadDoc(item.fileId, item.type, item.originalName)
                    }
                    disabled={loadingDownload}
                  >
                    {loadingDownload === item.fileId ? (
                      <Spinner
                        animation="border"
                        style={{ width: "15px", height: "15px" }}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </div>
              </div>
              <hr />
            </div>
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-between">Belum ada lampiran</div>
      )}
    </Fragment>
  );
};

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchForm from "../../components/Form/SearchForm";
import { Accordion, Container } from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import { resetClaim, setSearchClaim } from "../../store/claim/actions";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const ListClaim = () => {
  const dataSearchClaim = useSelector((state) => state.claim.dataSearchClaim);
  const { storedValues, storedPage } = dataSearchClaim;

  const [dataClaim, setDataClaim] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-container/page/${storedPage}?noClaim=${storedValues.noClaim}&policyNumber=${storedValues.policyNumber}&containerNo=${storedValues.containerNo}&certificateNo=${storedValues.certificateNo}&insured=${storedValues.insured}&accidentType=&minAmount=${storedValues.minAmount}&maxAmount=${storedValues.maxAmount}&startDate=${storedValues.startDateClaim}&endDate=${storedValues.endDateClaim}&statusCode=${storedValues.status}`,
      callback: (res) => {
        setLoading(false);
        setDataClaim(res.data.data);
        setTotalPage(res?.data?.totalPage);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
      },
    });
  }, [
    storedPage,
    storedValues.noClaim,
    storedValues.policyNumber,
    storedValues.certificateNo,
    storedValues.containerNo,
    storedValues.insured,
    storedValues.minAmount,
    storedValues.maxAmount,
    storedValues.startDateClaim,
    storedValues.endDateClaim,
    storedValues.status,
  ]);

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchClaim({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch({
      type: "SET_DATA_SEARCH_CLAIM",
      payload: {
        ...dataSearchClaim,
        storedPage: page,
      },
    });
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    dispatch(resetClaim());
  };

  const inputs = [
    {
      name: "noClaim",
      label: "No Klaim",
      type: "text",
      placeholder: "No Klaim",
    },
    {
      name: "policyNumber",
      label: "Policy Number",
      type: "text",
      placeholder: "Policy Number",
    },
    {
      name: "certificateNo",
      label: "No Sertifikat",
      type: "text",
      placeholder: "No Sertifikat",
    },
    {
      name: "containerNo",
      label: "No Kontainer",
      type: "text",
      placeholder: "No Kontainer",
    },
    {
      name: "insured",
      label: "Insured Name",
      type: "text",
      placeholder: "Insured Name",
    },
    {
      name: "minAmount",
      label: "Min Amount",
      type: "number",
      placeholder: "Min Amount",
    },
    {
      name: "maxAmount",
      label: "Max Amount",
      type: "number",
      placeholder: "Max Amount",
    },
    {
      name: "startDateClaim",
      label: "Tanggal Awal Klaim",
      type: "date",
      placeholder: "Tanggal Awal Klaim",
    },
    {
      name: "endDateClaim",
      label: "Tanggal Akhir Klaim",
      type: "date",
      placeholder: "Tanggal Akhir Klaim",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      placeholder: "Status",
      options: [
        {
          label: "Initial Report",
          value: "100",
        },
        {
          label: "Waiting for Document",
          value: "110",
        },
        {
          label: "Checking Document",
          value: "120",
        },
        {
          label: "Claim Approved",
          value: "300",
        },
        {
          label: "Claim Paid",
          value: "310",
        },
        {
          label: "Claim Rejected",
          value: "400",
        },
      ],
    },
  ];

  const headers = [
    {
      title: ["No. Klaim", "No. Sertifikat.", "No. Polis"],
    },
    {
      title: ["Insured", "No Kontainer"],
    },
    {
      title: ["Estimasi Kerugian", "Tanggal Klaim"],
    },
    {
      title: ["TSI", "Premi", "Status"],
    },
  ];

  const isDirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };

  return (
    <section>
      <Container className="py-5 mt-1">
        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isDirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div className="mt-5">
          <DataTable
            active="list-claim"
            headers={headers}
            data={dataClaim}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
          />
        </div>
      </Container>
    </section>
  );
};

export default ListClaim;

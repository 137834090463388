export default function ScrollToHere(id) {
  let element = document.getElementById(id);
  let headerOffset = 78;
  let elementPosition = element.getBoundingClientRect().top;
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
  });
}

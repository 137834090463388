import { Link } from "react-router-dom";

function ClaimLink({ value, id, detail }) {
  const encode = window.btoa(id);

  return value ? (
    <Link
      to={`/claim/detail/${encode}`}
      style={{ textDecoration: "none", color: detail ? "#fff" : "#106c85" }}
    >
      <b>{value}</b>
    </Link>
  ) : (
    "-"
  );
}
export default ClaimLink;

export const setClaimSubmission = (payload) => {
  return {
    type: "SET_DATA_CLAIM_SUBMISSION",
    payload,
  };
};

export const setSearchClaim = (payload) => {
  return {
    type: "SET_DATA_SEARCH_CLAIM",
    payload,
  };
};

export const resetClaim = (payload) => {
  return {
    type: "RESET_CLAIM",
    payload,
  };
};

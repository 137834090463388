import { Spinner } from "react-bootstrap";
import "./Spinner.css";

export default function SpinnerPrimary() {
  return (
    <div className="spinner-wrapper">
      <Spinner animation="grow" className="spinner-load1" />
      <Spinner animation="grow" className="spinner-load2" />
      <Spinner animation="grow" className="spinner-load3" />
    </div>
  );
}

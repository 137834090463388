import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import axiosGet from "../../helpers/axiosGet";
import { Link, useParams, useNavigate } from "react-router-dom";
import "./Claim.css";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import DetailCertificate from "../../components/DetailCertificate/DetailCertificate";
import { setClaimSubmission } from "../../store/claim/actions";
import moment from "moment";
import "moment/locale/id";
import getBase64 from "../../helpers/getBase64";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #bcbcbc;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#27AE60"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

export default function ClaimSubmission() {
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const [loading, setLoading] = useState(false);
  const [error, setErr] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [detail, setDetail] = useState();
  const [containerList, setContainerList] = useState([]);
  const [containerId, setContainerId] = useState(dataClaim?.containerId ?? []);
  const [containerNo, setContainerNo] = useState(dataClaim?.containerNo ?? []);
  const [dataContainer, setDataContainer] = useState(
    dataClaim?.dataContainer ?? []
  );
  const [filenameEor, setNameEor] = useState(dataClaim?.filenameEor ?? null);
  const [filenameOther, setNameOther] = useState(
    dataClaim?.filenameOther ?? null
  );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({ defaultValues: dataClaim });

  const eorDocValue = watch("eorDoc");
  const otherDocValue = watch("otherDoc");

  const decode = window.atob(params.id);

  useEffect(() => {
    setLoading(true);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${decode}`,
      callback: (res) => {
        setLoading(false);
        setContainerList(
          res?.data?.attachment?.filter((item) => item.isClaimed === false)
        );
        setDetail(res.data);
      },
      errorCallback: (err) => {
        setLoading(false);
        setErr(err);
        console.log(error);
      },
    });
  }, [decode, error]);

  const handleFileEor = (e) => {
    const file = e.target.files[0];
    setNameEor(file.name);
    if (file.size > 2_000_000) {
      setError("eorDoc", {
        type: "manual",
        message: `ukuran file maksimal 2MB.`,
        shouldFocus: true,
      });
    } else {
      clearErrors("eorDoc");
      getBase64(file, "eorDoc");
    }
  };

  const handleOtherDoc = (e) => {
    const file = e.target.files[0];
    setNameOther(file.name);

    if (file.size > 2_000_000) {
      setError("otherDoc", {
        type: "manual",
        message: `ukuran file maksimal 2MB.`,
        shouldFocus: true,
      });
    } else {
      clearErrors("otherDoc");
      getBase64(file, "otherDoc");
    }
  };

  const columns = [
    {
      dataField: "containerNo",
      text: "Nomor Container",
    },
    {
      dataField: "jobNo",
      text: "Job No",
    },
    {
      dataField: "sealNo",
      text: "Seal No",
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ fontSize: "12px" }}
    >
      {" "}
      Menampilkan {from} hingga {to} dari {size} Hasil
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Sebelumnya",
    nextPageText: "Selanjutnya",
    lastPageText: "Terakhir",
    nextPageTitle: "Halaman Pertama",
    prePageTitle: "Pre page",
    firstPageTitle: "Halaman Selanjutnya",
    lastPageTitle: "Halaman Terakhir",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: containerList.length,
      },
    ],
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setContainerId([...containerId, row.id]);
      setContainerNo([...containerNo, row.containerNo]);
      setDataContainer([...dataContainer, row]);
      clearErrors("containerList");
    } else {
      setContainerId(containerId.filter((item) => item !== row.id));
      setContainerNo(containerNo.filter((item) => item !== row.containerNo));
      setDataContainer(
        dataContainer.filter((item) => item.containerNo !== row.containerNo)
      );
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const conId = rows.map((r) => r.id);
    const conNo = rows.map((r) => r.containerNo);
    const dataCont = rows.map((r) => r);
    if (isSelect) {
      setContainerId(conId);
      setContainerNo(conNo);
      setDataContainer(dataCont);
      clearErrors("containerList");
    } else {
      setContainerId([]);
      setContainerNo([]);
      setDataContainer([]);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    selected: containerId,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const compareContainerNumbers = (a, b) => {
    const containerNoA = parseInt(a.containerNo.match(/\d+/));
    const containerNoB = parseInt(b.containerNo.match(/\d+/));

    return containerNoA - containerNoB;
  };

  const onSubmit = (data) => {
    if (containerId?.length <= 0) {
      setError("containerList", {
        type: "manual",
        message: `Minimal pilih 1 container!`,
        shouldFocus: true,
      });
    } else {
      const today = moment().format("YYYY-MM-DD");
      data.nextProgressDeadlineDate = today;
      data.conveyance = `${detail.vesselName} - ${detail.vesselDescription} - ${detail.otherTransport}`;
      data.publishDate = today;
      data.declarationUniqId = detail.uniqId;
      data.companyName = detail.companyName;
      data.insuranceCode = detail.insurerCode;
      data.policyNumber = detail.policyNumber;
      data.certificateNo = detail.certificateNumber;
      data.containerId = containerId;
      data.containerNo = containerNo;
      data.filenameEor = filenameEor;
      data.filenameOther = filenameOther;
      data.dataContainer = dataContainer?.sort(compareContainerNumbers);

      dispatch(setClaimSubmission(data));
      navigate(`/claim/submission/summary`);
    }
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <Form
        className="form-submission"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Row>
          <Col xs={12} md={8}>
            <h5 className="pl-4 pr-4 mt-2 title-header-right">Form Klaim</h5>
            <Card className="card-wrapper p-4 mb-4">
              <Card.Body className="main-card">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>
                    Silakan Pilih Container{" "}
                    <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="containerList"
                    {...register("containerList")}
                    render={({ field }) => (
                      <BootstrapTable
                        {...field}
                        name={field.name}
                        id={field.name}
                        value={field.value}
                        keyField="id"
                        data={containerList}
                        columns={columns}
                        selectRow={selectRow}
                        striped
                        hover
                        condensed
                        onBlur={field.onBlur}
                        isInvalid={errors.claimAmount}
                        getInputRef={field.ref}
                        pagination={paginationFactory(options)}
                      />
                    )}
                  />
                  {errors["containerList"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["containerList"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nama PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nama PIC"
                    name="picName"
                    {...register("picName", {
                      required: "nama pic wajib diisi!",
                    })}
                  />
                  {errors["picName"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picName"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Email PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email PIC"
                    name="picEmail"
                    {...register("picEmail", {
                      required: "email pic wajib diisi!",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "email tidak valid!",
                      },
                    })}
                  />
                  {errors["picEmail"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picEmail"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Nomor Telepon PIC <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Nomor Telepon PIC"
                    name="picPhoneNumber"
                    {...register("picPhoneNumber", {
                      required: "nomor telepon PIC wajib diisi!",
                    })}
                  />
                  {errors["picPhoneNumber"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["picPhoneNumber"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Tanggal Kejadian <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Tanggal Kejadian"
                    name="accidentDate"
                    max={new Date().toISOString().split("T")[0]}
                    min={moment(detail?.policyIssueDate).format("YYYY-MM-DD")}
                    {...register("accidentDate", {
                      required: "tanggal kejadian wajib diisi!",
                    })}
                  />
                  {errors["accidentDate"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["accidentDate"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Lokasi Kejadian <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Lokasi Kejadian"
                    name="accidentLocation"
                    {...register("accidentLocation", {
                      required: "lokasi kejadian wajib diisi!",
                    })}
                  />
                  {errors["accidentLocation"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["accidentLocation"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Detail Kronologi Kejadian{" "}
                    <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="accidentDetail"
                    {...register("accidentDetail", {
                      required: "detail kronologi kejadian wajib diisi!",
                    })}
                  />
                  {errors["accidentDetail"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["accidentDetail"].message}
                    </p>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Estimasi Kerugian <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="claimAmount"
                    rules={{
                      required: true && "Estimasi kerugian wajib diisi!",
                    }}
                    {...register("claimAmount")}
                    render={({ field }) => (
                      <NumberFormat
                        {...field}
                        name={field.name}
                        id={field.name}
                        thousandSeparator="."
                        decimalSeparator=","
                        value={field.value}
                        onValueChange={(value) => {
                          return field.onChange(value.formattedValue);
                        }}
                        onBlur={field.onBlur}
                        className={`form-control ${
                          errors.claimAmount ? "is-invalid" : ""
                        }`}
                        isInvalid={errors.claimAmount}
                        getInputRef={field.ref}
                        placeholder="Estimasi Kerugian"
                      />
                    )}
                  />
                  {errors["claimAmount"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["claimAmount"].message}
                    </p>
                  )}
                </Form.Group>

                <label htmlFor="eorDoc" className="mb-2 mt-2">
                  Upload EOR <span className="text-danger">*</span>{" "}
                </label>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id="eorDoc"
                  name="eorDoc"
                  {...register("eorDoc", {
                    onChange: handleFileEor,
                    required: dataClaim.filenameEor
                      ? false
                      : "EOR wajib diupload!",
                  })}
                  style={{ display: "none" }}
                  accept={".doc, .docx, .pdf, .jpg, .jpeg, .png"}
                />

                <StyledInput>
                  <span>
                    {eorDocValue?.length
                      ? eorDocValue[0].name
                      : dataClaim.filenameEor
                      ? dataClaim.filenameEor
                      : "Pilih file"}
                  </span>
                  <div>
                    <label htmlFor="eorDoc">
                      <div style={{ cursor: "pointer" }}>
                        <StyledButton type="button" isUpload>
                          Lampirkan
                        </StyledButton>
                      </div>
                    </label>
                  </div>
                </StyledInput>

                {errors["eorDoc"] && (
                  <p className="mb-0 mt-1 text-danger small">
                    *{errors["eorDoc"].message}
                  </p>
                )}

                <Form.Group>
                  <Form.Label htmlFor="otherDoc" className="mb-2 mt-2">
                    Foto - foto dokumentasi dan atau rekaman video (kontainer
                    yang rusak) <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    className="form-control form-control-lg"
                    id="otherDoc"
                    name="otherDoc"
                    {...register("otherDoc", {
                      onChange: handleOtherDoc,
                      required: dataClaim.filenameOther
                        ? false
                        : "Foto dokumentasi wajib diupload!",
                    })}
                    hidden
                    accept={".doc, .docx, .pdf, .jpg, .jpeg, .png"}
                  />

                  <StyledInput>
                    <span>
                      {otherDocValue?.length
                        ? otherDocValue[0].name
                        : dataClaim.filenameOther
                        ? dataClaim.filenameOther
                        : "Pilih file"}
                    </span>
                    <div>
                      <Form.Label htmlFor="otherDoc">
                        <div style={{ cursor: "pointer" }}>
                          <StyledButton type="button" isUpload>
                            Lampirkan
                          </StyledButton>
                        </div>
                      </Form.Label>
                    </div>
                  </StyledInput>
                  {errors["otherDoc"] && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{errors["otherDoc"].message}
                    </p>
                  )}
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col xs={12} md={4}>
            <DetailCertificate detail={detail} />
          </Col>
        </Row>

        <Row className="mt-5">
          <Col lg={12} className="d-flex justify-content-between">
            <Link to="/claim" style={{ textDecoration: "none" }}>
              <Button
                type="button"
                color="primary"
                size="small"
                className="d-flex justify-content-between align-items-center back-button"
              >
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
                BACK
              </Button>
            </Link>
            <Button
              type="submit"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
              disabled={errors["eorDoc"] || errors["otherDoc"] ? true : false}
            >
              NEXT
              <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

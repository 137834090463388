import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Toast } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DetailCertificate from "../../components/DetailCertificate/DetailCertificate";
import SpinnerPrimary from "../../components/Spinner/Spinner";
import axiosGet from "../../helpers/axiosGet";
import axiosPost from "../../helpers/axiosPost";
import uploadDocClaim from "../../helpers/uploadDocClaim";
import "./Claim.css";
import TableContainer from "../../components/DataTable/TableContainer";

export default function ClaimSummary() {
  const dataClaim = useSelector((state) => state.claim.dataClaimSubmission);
  const eorDoc = localStorage.getItem("eorDoc");
  const otherDoc = localStorage.getItem("otherDoc");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false); //toast

  const navigate = useNavigate();
  const [detail, setDetail] = useState();

  const encode = window.btoa(dataClaim.declarationUniqId);

  useEffect(() => {
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-cargo-container/uniq-id/${dataClaim.declarationUniqId}`,
      callback: (res) => {
        setDetail(res.data);
        setLoading(false);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
      },
    });
  }, [dataClaim.declarationUniqId]);

  const handleSubmit = () => {
    setLoading(true);
    const body = {
      accidentType: dataClaim.accidentType,
      accidentDate: dataClaim.accidentDate,
      accidentDetail: dataClaim.accidentDetail,
      accidentLocation: dataClaim.accidentLocation,
      nextProgressUserType: "-",
      nextProgressDeadlineDate: dataClaim.nextProgressDeadlineDate,
      insuranceCode: dataClaim.insuranceCode,
      companyName: dataClaim.companyName,
      picName: dataClaim.picName,
      picPhoneNumber: dataClaim.picPhoneNumber,
      picEmail: dataClaim.picEmail,
      containerNo: dataClaim?.containerNo,
      policyNumber: dataClaim.policyNumber,
      claimAmount: dataClaim.claimAmount?.split(".").join(""),
      conveyance: dataClaim.conveyance,
      certificateNo: dataClaim.certificateNo,
      publishDate: dataClaim.publishDate,
      declarationUniqId: dataClaim.declarationUniqId,
      declarationAttachmentId: dataClaim.containerId,
    };
    axiosPost({
      url: `/claim-container/save`,
      data: body,
      callback: (res) => {
        const encode = window.btoa(res.data.claimContainerId);
        const refId = detail?.refId;
        setShow(true);
        setLoading(false);
        uploadDocClaim(
          eorDoc,
          refId,
          res?.data?.claimContainerId,
          "DOC_EIR",
          dataClaim.filenameEor
        );
        uploadDocClaim(
          otherDoc,
          refId,
          res?.data?.claimContainerId,
          "DOC_DOCUMENTATION",
          dataClaim.filenameOther
        );
        setTimeout(() => {
          navigate(`/claim/detail/${encode}`);
        }, [6000]);
      },
      errorCallback: (err) => {
        setError(err);
        setLoading(false);
        setShow(true);
        console.log(err);
      },
    });
  };

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <SpinnerPrimary />
    </div>
  ) : (
    <div className="container">
      <Row>
        <Col xs={12} md={8}>
          <h5 className="pl-4 pr-4 mt-2 title-header-right">Detail Klaim</h5>
          <Card className="card-wrapper p-4 mb-4">
            <Card.Body className="main-card">
              <p className="title">Insured</p>
              <div className="insured">
                <h5 className="m-0">{detail?.insuredName ?? "-"}</h5>
              </div>

              <Row className="mt-4">
                <Col md={6}>
                  <p className="title">Tanggal Pengajuan</p>
                  <p className="value">
                    {moment(dataClaim?.publishDate).format("DD MMM YYYY") ??
                      "-"}
                  </p>
                  <p className="title">Nama PIC</p>
                  <p className="value">{dataClaim?.picName ?? "-"}</p>

                  <p className="title">Conveyance</p>
                  <p className="value">{dataClaim?.conveyance ?? "-"}</p>
                </Col>
                <Col md={6}>
                  <p className="title">Email PIC</p>
                  <p className="value">{dataClaim?.picEmail ?? "-"}</p>

                  <p className="title">No Handphone PIC</p>
                  <p className="value">{dataClaim?.picPhoneNumber ?? "-"}</p>
                </Col>
              </Row>
              <hr />
              <h6 className="pl-4 pr-4 mb-2 title-header-right">
                Detail Kontainer
              </h6>
              <Row>
                <Col md={12}>
                  <TableContainer data={dataClaim?.dataContainer} />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="card-wrapper p-4 mb-4">
            <Card.Header className="card-main-header">
              Detail Kronologi
            </Card.Header>
            <Card.Body className="main-card">
              <p className="title">Tanggal Kejadian</p>
              <p className="value">
                {moment(dataClaim.accidentDate).format("DD MMM YYYY") ?? "-"}
              </p>

              <p className="title">Lokasi Kejadian</p>
              <p className="value">{dataClaim.accidentLocation ?? "-"}</p>

              <p className="title">Detail Kronologi Kejadian</p>
              <p className="value">{dataClaim.accidentDetail ?? "-"}</p>
            </Card.Body>
          </Card>

          <Card
            className="card-wrapper p-4 mb-4"
            style={{ minHeight: "100px" }}
          >
            <Card.Header className="card-main-header">
              Daftar Lampiran
            </Card.Header>
            <Card.Body className="main-card">
              <div className="d-flex justify-content-between">
                <div>Lampiran EOR</div>
                <div>
                  <a
                    className="download-doc"
                    href={eorDoc}
                    download={"Dokumen-EOR"}
                  >
                    Download
                  </a>
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <div>Lampiran Foto - Foto Dokumentasi</div>
                <div>
                  <a
                    className="download-doc"
                    href={otherDoc}
                    download={"Dokumen-lainnya"}
                  >
                    Download
                  </a>
                </div>
              </div>
              <hr />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={4}>
          <DetailCertificate detail={detail} claimSubmission={dataClaim} />
        </Col>
      </Row>

      <Row>
        <Col lg={12} className="d-flex justify-content-between">
          <Link
            to={`/claim/submission/${encode}`}
            style={{ textDecoration: "none" }}
          >
            <Button
              type="button"
              color="primary"
              size="small"
              className="d-flex justify-content-between align-items-center back-button"
            >
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              KEMBALI
            </Button>
          </Link>

          <Button
            onClick={handleSubmit}
            type="button"
            color="primary"
            size="small"
            className="d-flex justify-content-between align-items-center back-button"
          >
            SUBMIT
            <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
          </Button>
        </Col>
      </Row>

      <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
        <Toast.Header
          style={{ backgroundColor: error ? "#c6171766" : "#17c69666" }}
        >
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{error ? "Error" : "Success"}</strong>
        </Toast.Header>
        <Toast.Body>
          {error ? error.message : "Submit Berhasil! Mohon Menunggu..."}
        </Toast.Body>
      </Toast>
    </div>
  );
}

import styled from "styled-components";

const StyledHeading = styled.div`
  margin-top: ${(props) => props.status && "18rem"};
  margin-bottom: 3rem;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  color: #55a18f;

  hr {
    max-width: 100px;
    margin: 0 auto;
    border: #55a18f solid 2px;
  }

  @media (min-width: 768px) {
    margin-top: ${(props) => props.status && "14rem"};
  }
`;

const Subheading = ({ children, ...props }) => {
  return (
    <StyledHeading {...props} id="scrollToHere">
      {children}

      <hr />
    </StyledHeading>
  );
};

export default Subheading;

import moment from "moment";
import "moment/locale/id";
import React from "react";
import { Card } from "react-bootstrap";
import CurrencyFormat from "../Formating/CurrencyFormat";

export default function DetailCertificate({ detail, claimSubmission }) {
  return (
    <div>
      <h5 className="pl-4 pr-4 mt-2 title-header-right">Detail Sertifikat</h5>
      <Card className="card-wrapper p-4" style={{ minHeight: "350px" }}>
        <Card.Body className="main-card">
          <p className="title mb-0">No. Polis/No. Sertifikat</p>
          <p className="value-left">
            {detail?.policyNumber}/{detail?.certificateNumber ?? "-"}
          </p>
          <hr />

          <p className="title mb-0">Voyage</p>
          <p className="value-left">
            {detail?.portDeparture || detail?.portDestination
              ? `From ${detail?.portDeparture} To ${detail?.portDestination}`
              : "-"}
          </p>

          <p className="title mb-0">Kendaraan</p>
          <p className="value-left">
            {detail?.vesselName ? detail?.vesselName : "-"}
          </p>

          <p className="title mb-0">Tanggal Keberangkatan</p>
          <p className="value-left">
            {moment(detail?.estDepartureDate).format("DD MMM YYYY")}
          </p>

          <p className="title mb-0">Tanggal Terbit</p>
          <p className="value-left">
            {moment(detail?.policyIssueDate).format("DD MMM YYYY") ?? "-"}
          </p>
        </Card.Body>
      </Card>

      <Card
        className="px-3 py-4 mt-4 card-wrapper"
        style={{ minHeight: "100px" }}
      >
        <Card.Body className="main-card">
          <p className="title mb-0">Premi & Biaya Layanan</p>
          <p className="value-left">{CurrencyFormat(detail?.premium)}</p>

          <p className="title mb-0">Biaya Materai</p>
          <p className="value-left">{CurrencyFormat(detail?.stampDuty ?? 0)}</p>

          {claimSubmission ? (
            <>
              <p className="title mb-0">Net Premium</p>
              <p className="value-left">
                {CurrencyFormat(detail?.netPremium ?? 0)}
              </p>
              <hr />
              <p className="title mb-0">Estimasi Kerugian</p>
              <p className="value-left">{claimSubmission?.claimAmount ?? 0}</p>
            </>
          ) : (
            <>
              <hr />
              <p className="title mb-0">Net Premium</p>
              <p className="value-left">
                {CurrencyFormat(detail?.netPremium ?? 0)}
              </p>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

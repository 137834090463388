import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import SpinnerPrimary from "../../components/Spinner/Spinner";

const Home = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // get queries
  const token = searchParams.get("token");
  const user = searchParams.get("user");

  const localToken = localStorage.getItem("token");
  const localUser = localStorage.getItem("user");
  const val = searchParams.get("val");
  const decode = window.atob(val);
  const decodeTokenResult = decode.split("%20").join(" ");

  useEffect(() => {
    if (val) {
      sessionStorage.setItem("token", decodeTokenResult);
      sessionStorage.setItem("user", user);
      localStorage.setItem("token", decodeTokenResult);
      localStorage.setItem("user", user);
      setTimeout(() => {
        navigate("/declaration");
      }, 2000);
    } else if ((token && user) || (localToken && localUser)) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("user", user);
      localStorage.setItem("token", token);
      localStorage.setItem("user", user);

      setTimeout(() => {
        navigate("/declaration");
      }, 2000);
    } else {
      setTimeout(() => {
        navigate("/404");
      }, 2000);
    }
  }, [navigate, token, user, localToken, localUser, val, decodeTokenResult]);

  return (
    <Container>
      <div className="mt-5 mb-5 pt-5 pb-5">
        <SpinnerPrimary />
      </div>
    </Container>
  );
};

export default Home;

import moment from "moment";
import React, { Fragment, useState } from "react";
import { Pagination } from "../Pagination/Pagination";
import { Card, Table, Badge, Spinner } from "react-bootstrap";
import CurrencyFormat from "../Formating/CurrencyFormat";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import "./DataTable.css";
import SpinnerPrimary from "../Spinner/Spinner";
import axiosGet from "../../helpers/axiosGet";
import ClaimLink from "../ClaimLink/ClaimLink";
import "moment/locale/id";

const DataTable = ({
  headers,
  data,
  loading,
  error,
  page,
  totalPage,
  onPageChange,
  active,
}) => {
  return (
    <>
      <Card className="w-100" style={{ border: "none", borderRadius: "10px" }}>
        {loading ? (
          <div style={{ marginTop: "200px", marginBottom: "200px" }}>
            <SpinnerPrimary />
          </div>
        ) : error ? (
          <Card.Body>
            <Card.Text color="danger" align="center">
              {error.message}
            </Card.Text>
          </Card.Body>
        ) : data.length ? (
          <Card.Body>
            <Fragment>
              <Table
                className="w-100 text-center table-striped"
                responsive="lg"
              >
                <thead>
                  <Headers headers={headers} active={active} />
                </thead>
                <tbody>
                  {data.map((x) => (
                    <Body row={x} active={active} key={x.id} />
                  ))}
                </tbody>
              </Table>
            </Fragment>
          </Card.Body>
        ) : (
          <Card.Body style={{ textAlign: "center" }}>
            <Card.Text>NO DATA</Card.Text>
          </Card.Body>
        )}

        <>
          {totalPage > 1 && (
            <div className="mt-5 d-flex justify-content-end">
              <Pagination
                pageCount={totalPage}
                onPageChange={onPageChange}
                onPageActive={page}
              />
            </div>
          )}
        </>
      </Card>
    </>
  );
};

function Headers({ headers, active }) {
  switch (active) {
    case "declaration":
      return (
        <tr>
          <SubHeaders headers={headers} />
          <th className="th-table">
            <div>Detail Sertifikat</div>
            <div>Aksi</div>
          </th>
        </tr>
      );
    default:
      return (
        <tr>
          <SubHeaders headers={headers} />
          <th className="th-table">
            <div>Detail</div>
          </th>
        </tr>
      );
  }
}

function SubHeaders({ headers }) {
  return (
    <>
      {headers.map((header, index) => (
        <th key={index} className="th-table">
          {header.title.map((title) => (
            <div key={title}>{title}</div>
          ))}
        </th>
      ))}
    </>
  );
}

function Body({ row, active }) {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [loadingExpanded, setLoadingExpanded] = useState(false);
  const [error, setError] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [allIsClaimed, setAllIsClaimed] = useState(false);

  const handleDetail = () => {
    const encode = window.btoa(row.id);
    const encodeUniqId = window.btoa(row.uniqId);

    switch (active) {
      case "declaration":
        return navigate(`/declaration/detail/${encodeUniqId}`);
      case "list-claim":
        return navigate(`/claim/detail/${encode}`);
      default:
        return navigate("/");
    }
  };

  const showContainer = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
      setLoadingExpanded(true);
      axiosGet({
        url: `/claim-cargo-container/attachment/uniq-id/${row.uniqId}`,
        callback: (res) => {
          setLoadingExpanded(false);
          setAttachment(res.data);
          const attachment = res.data;
          const allIsClaimed = attachment.every((item) => item.isClaimed);
          setAllIsClaimed(allIsClaimed);
        },
        errorCallback: (err) => {
          setLoadingExpanded(false);
          setError(err);
        },
      });
    }
  };

  const handleClaim = (uniqId) => {
    const encode = window.btoa(uniqId);
    navigate(`/claim/submission/${encode}`);
  };

  return (
    <Fragment>
      <tr
        {...(active === "declaration" && {
          onClick: () => showContainer(row.uniqId),
        })}
        style={{ cursor: "pointer" }}
      >
        <Cell active={active} row={row} expanded={expanded} />
        {active === "declaration" ? (
          <td className="td-table">
            <div className="dec-detail" onClick={handleDetail}>
              Detail Sertifikat
            </div>
            {expanded && !loadingExpanded && (
              <div
                className={`${
                  allIsClaimed
                    ? "action-detail-claim-disabled"
                    : "action-detail-claim"
                } `}
                {...(!allIsClaimed && {
                  onClick: () => handleClaim(row.uniqId),
                })}
              >
                Ajukan Klaim
              </div>
            )}
          </td>
        ) : (
          <td className="td-table" style={{ verticalAlign: "middle" }}>
            <div className="action-detail" onClick={handleDetail}>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                style={{ color: "#006881", fontSize: "20px" }}
              ></FontAwesomeIcon>
            </div>
          </td>
        )}
      </tr>

      {expanded && (
        <>
          {loadingExpanded ? (
            <tr>
              <ExpandedCell active="loading" />
            </tr>
          ) : error ? (
            <>
              <ExpandedCell active="error" message={error.message} />
            </>
          ) : (
            <>
              {attachment.length > 0 ? (
                <>
                  {attachment.map((x, index) => (
                    <tr key={index}>
                      <ExpandedCell active={active} row={x} />
                      <td
                        className="td-expanded-table"
                        style={{ background: "#106c85" }}
                      >
                        {!x.isClaimed ? (
                          <FontAwesomeIcon
                            icon={faAngleDoubleLeft}
                            style={{ color: "#fff", fontSize: "12px" }}
                          ></FontAwesomeIcon>
                        ) : (
                          <Badge bg="light" text="dark">
                            <ClaimLink
                              id={x?.claimContainerId}
                              value="Claimed"
                            />
                          </Badge>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  <tr>
                    <ExpandedCell active="not-found" />
                  </tr>
                </>
              )}
            </>
          )}
        </>
      )}
    </Fragment>
  );
}

function ExpandedCell({ active, row, message }) {
  switch (active) {
    case "declaration":
      return (
        <Fragment>
          <td className="td-expanded-table">{row.containerNo ?? "-"}</td>
          <td className="td-expanded-table"></td>
          <td className="td-expanded-table">
            <div>{moment(row.createdDate).format("DD MMMM YYYY")}</div>
          </td>
          <td className="td-expanded-table">{CurrencyFormat(row.premium)}</td>
        </Fragment>
      );
    case "loading":
      return (
        <Fragment>
          <td colSpan={5} className="td-expanded-table">
            {[1, 2, 3].map((index) => (
              <Spinner
                animation="grow"
                className="loading-expanded"
                key={index}
              />
            ))}
          </td>
        </Fragment>
      );
    case "not-found":
      return (
        <Fragment>
          <td colSpan={5} className="td-expanded-table">
            Data container tidak ditemukan
          </td>
        </Fragment>
      );
    case "error":
      return (
        <Fragment>
          <td colSpan={5} className="td-expanded-table">
            {message}
          </td>
        </Fragment>
      );
    default:
      return "-";
  }
}

function Cell({ active, row }) {
  switch (active) {
    case "declaration":
      return (
        <Fragment>
          <td className="td-table">
            {row.policyNumber} / {row.certificateNumber}
          </td>
          <td className="td-table">
            <div>{row.insuredName}</div>
            <div>
              {row.portDeparture} - {row.portDestination}
            </div>
          </td>
          <td className="td-table">
            <div>{moment(row.createdDate).format("DD MMMM YYYY")}</div>
            <div>{moment(row.estDepartureDate).format("DD MMMM YYYY")}</div>
          </td>
          <td className="td-table">
            <div>{row.statusName}</div>
            <div>{CurrencyFormat(row.premium)}</div>
          </td>
        </Fragment>
      );
    case "list-claim":
      return (
        <Fragment>
          <td className="td-table">
            <div>{row?.claim?.noClaim ?? "-"}</div>
            <div>{row?.certificateNo ?? "-"}</div>
            <div>{row?.claim?.policyNumber ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{row.insured ?? "-"}</div>
            <div>{row?.containerNo ?? "-"}</div>
          </td>
          <td className="td-table">
            <div>{CurrencyFormat(row.claimAmount)}</div>
            <div>{moment(row.reportDate).format("DD MMMM YYYY")}</div>
          </td>
          <td className="td-table">
            <div>{CurrencyFormat(row?.claim?.sumInsured)}</div>
            <div>{CurrencyFormat(row?.claim?.netPremium)}</div>
            <div>{row?.claim?.statusName}</div>
          </td>
        </Fragment>
      );
    default:
      return "-";
  }
}

export default DataTable;

import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export default function TableContainer({ data }) {
  const columns = [
    {
      dataField: "containerNo",
      text: "Nomor Container",
      headerAlign: "center",
    },
    {
      dataField: "jobNo",
      text: "Job No",
      headerAlign: "center",
    },
    {
      dataField: "sealNo",
      text: "Seal No",
      headerAlign: "center",
    },
  ];

  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ fontSize: "12px" }}
    >
      {" "}
      Menampilkan {from} hingga {to} dari {size} Hasil
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Sebelumnya",
    nextPageText: "Selanjutnya",
    lastPageText: "Terakhir",
    nextPageTitle: "Halaman Pertama",
    prePageTitle: "Pre page",
    firstPageTitle: "Halaman Selanjutnya",
    lastPageTitle: "Halaman Terakhir",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  };

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      striped
      hover
      condensed
      pagination={paginationFactory(options)}
    />
  );
}

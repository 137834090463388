import { useEffect } from "react";
import { useForm } from "react-hook-form";
import "./FormInput.css";
import { Button } from "react-bootstrap";
import FormInput from "./FormInput";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const SearchForm = ({ defaultValues, handleSearch, handleReset, inputs }) => {
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: defaultValues,
  });

  const onSubmit = (data) => {
    if (data.maxPremium || data.minPremium) {
      data.maxPremium = data.maxPremium.split(".").join("");
      data.minPremium = data.minPremium.split(".").join("");
    }
    handleSearch(data);
  };

  const onReset = () => {
    reset();
    handleReset();
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row className="g-3">
        {inputs.map((field) => (
          <Col
            key={field.name}
            md="4"
            className="mt-4 d-flex justify-content-center"
          >
            <FormInput
              name={field.name}
              label={field.label}
              type={field.type}
              placeholder={field.placeholder}
              options={field.options}
              register={register}
              control={control}
            />
          </Col>
        ))}
      </Row>

      <div className="mt-5 d-flex justify-content-end search-btn">
        <div>
          <Button
            type="button"
            color="secondary"
            size="small"
            onClick={onReset}
          >
            RESET
          </Button>
        </div>

        <div className="ms-2">
          <Button type="submit" color="primary" size="small">
            CARI <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SearchForm;

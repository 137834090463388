import React from "react";
import NotFoundImg from "../../images/notfound.svg";

export default function NotFound() {
  return (
    <div className="container d-flex justify-content-center align-items-center p-5">
      <img src={NotFoundImg} alt="notfound" className="w-50" />
    </div>
  );
}

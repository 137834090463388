import { Container, Modal, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import LogoWhite from "../../logos/Logo_Polismall-white.png";
import LogoColor from "../../logos/Logo_Polismall-green.png";
import "./Navbar.css";

export default function NavbarPrimary() {
  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const currentPath = window.location.pathname;

  // change navbar color when scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 56) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // cek route
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <Navbar
        style={{ boxShadow: "none" }}
        collapseOnSelect
        expand="lg"
        fixed="top"
        className={
          scroll && pathname === "/"
            ? "scroll shadow-sm"
            : scroll && pathname !== "/"
            ? "second-scroll"
            : pathname !== "/"
            ? "second-scroll"
            : "default-navbar"
        }
      >
        <Container>
          <Link to="/">
            <img
              alt="Logo"
              src={LogoColor}
              style={{ height: 50, cursor: "pointer" }}
            />
          </Link>

          <button className="toggle-nav" onClick={handleShow}>
            <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
          </button>

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-end"
          >
            <Nav.Link href="/declaration" className="nav-link-text">
              <span
                className={`ms-2 ${
                  currentPath.includes("declaration") && "active"
                }`}
              >
                Declaration
              </span>
            </Nav.Link>

            <Nav.Link href="/claim" className="nav-link-text">
              <span
                className={`ms-2 ${currentPath.includes("claim") && "active"}`}
              >
                Claim
              </span>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="border-0">
            <Link to="/">
              <img alt="Logo" src={LogoWhite} style={{ height: 30 }} />
            </Link>

            <button className="toggle-nav" onClick={handleClose}>
              <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
            </button>
          </Modal.Header>
          <Modal.Body>
            <Link
              to="#"
              className="text-white px-3 py-5 d-block my-5"
              style={{ fontSize: 30 }}
            >
              Declaration
            </Link>
            <Link
              to="#"
              className="text-white px-3 py-5 d-block my-5"
              style={{ fontSize: 30 }}
            >
              Claim
            </Link>
          </Modal.Body>
        </Modal>
      </Navbar>
    </>
  );
}

import React from "react";
import SuccessImg from "../../../images/completed.svg";
import Subheading from "../../../components/Subheading/Subheading";
export default function Success() {
  return (
    <div className="container text-center">
      <Subheading>
        <h3>Klaim Berhasil</h3>
      </Subheading>
      <div>
        <img src={SuccessImg} alt="success" style={{ width: "40%" }} />
      </div>
    </div>
  );
}

import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchForm from "../../components/Form/SearchForm";
import { Accordion, Container } from "react-bootstrap";
import ScrollToHere from "../../components/Scroll/ScrollToHere";
import axiosGet from "../../helpers/axiosGet";
import DataTable from "../../components/DataTable/DataTable";
import styled from "styled-components";
import {
  resetDeclaration,
  setSearchDeclaration,
} from "../../store/declaration/actions";
import { resetClaim } from "../../store/claim/actions";

const StyledAccordion = styled(Accordion)`
  .accordion-item:last-of-type {
    border-radius: 16px;
    border: none;
  }

  .accordion-body {
    padding: 30px 40px;
  }

  .accordion-item:first-of-type .accordion-button {
    border-radius: 10px;
    background: #fff;
    border: none;
    box-shadow: none;
  }

  .accordion-item:first-of-type .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid #eaeaea;
  }
`;

const Declaration = () => {
  const dataSearchDeclaration = useSelector(
    (state) => state.declaration.dataSearchDeclaration
  );
  const { storedValues, storedPage } = dataSearchDeclaration;

  const [dataDeclaration, setDataDeclaration] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetClaim());
    setLoading(true);
    setError(null);
    axiosGet({
      url: `/claim-cargo-container/page/${storedPage}?minDate&maxDate&insuredName=${storedValues.insuredName}&statusName&certificateNumber=${storedValues.certificateNumber}&uniqId=&statusCode=${storedValues.status}&policyNumber=${storedValues.policyNumber}&policyStartDate=${storedValues.startDateClaim}&policyEndDate=${storedValues.endDateClaim}&userCode&submitFrom=${storedValues.submitFrom}&submitTo=${storedValues.submitTo}&etdFrom=${storedValues.etdFrom}&etdTo=${storedValues.etdTo}&minPremium=${storedValues.minPremium}&maxPremium=${storedValues.maxPremium}&portDeparture=${storedValues.portDeparture}&portDestination=${storedValues.portDestination}&containerNo=${storedValues.containerNo}`,
      callback: (res) => {
        setLoading(false);
        setDataDeclaration(res.data.data);
        setTotalPage(res.data.totalPage);
      },
      errorCallback: (err) => {
        setLoading(false);
        setError(err);
      },
    });
  }, [
    dispatch,
    storedPage,
    storedValues.policyNumber,
    storedValues.certificateNumber,
    storedValues.containerNo,
    storedValues.insuredName,
    storedValues.portDeparture,
    storedValues.portDestination,
    storedValues.startDateClaim,
    storedValues.endDateClaim,
    storedValues.submitFrom,
    storedValues.submitTo,
    storedValues.etdFrom,
    storedValues.etdTo,
    storedValues.minPremium,
    storedValues.maxPremium,
    storedValues.status,
  ]);

  const handleSearch = (data) => {
    ScrollToHere("search-form");
    dispatch(
      setSearchDeclaration({
        storedValues: data,
        storedPage: 1,
      })
    );
  };

  const handlePageClick = (e) => {
    ScrollToHere("search-form");

    const page = e.selected + 1;
    dispatch(
      setSearchDeclaration({
        ...dataSearchDeclaration,
        storedPage: page,
      })
    );
  };

  const handleReset = () => {
    ScrollToHere("search-form");
    dispatch(resetDeclaration());
  };

  const inputs = [
    {
      name: "policyNumber",
      label: "No Polis",
      type: "text",
      placeholder: "No Polis",
    },
    {
      name: "certificateNumber",
      label: "No Sertifikat",
      type: "text",
      placeholder: "No Sertifikat",
    },
    {
      name: "containerNo",
      label: "No Kontainer",
      type: "text",
      placeholder: "No Kontainer",
    },
    {
      name: "insuredName",
      label: "Insured Name",
      type: "text",
      placeholder: "Tertanggung",
    },
    {
      name: "portDeparture",
      label: "Route Dari",
      type: "text",
      placeholder: "Rute Dari",
    },
    {
      name: "portDestination",
      label: "Rute Ke",
      type: "text",
      placeholder: "Rute Ke",
    },
    {
      name: "submitFrom",
      label: "Tanggal Submit Dari",
      type: "date",
      placeholder: "Tanggal Submit Dari",
    },
    {
      name: "submitTo",
      label: "Tanggal Submit Ke",
      type: "date",
      placeholder: "Tanggal Submit Ke",
    },
    {
      name: "etdFrom",
      label: "ETD From",
      type: "date",
      placeholder: "ETD Dari",
    },
    {
      name: "etdTo",
      label: "ETD To",
      type: "date",
      placeholder: "ETD Ke",
    },
    {
      name: "minPremium",
      label: "Min Premium",
      type: "number",
      placeholder: "Min Premium",
    },
    {
      name: "maxPremium",
      label: "Max Premium",
      type: "number",
      placeholder: "Max Premium",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      placeholder: "Status",
      options: [
        {
          label: "DRAFT",
          value: "0",
        },
        {
          label: "SUBMITTED",
          value: "100",
        },
        {
          label: "PAID",
          value: "200",
        },
        {
          label: "CANCEL",
          value: "300",
        },
      ],
    },
  ];

  const headers = [
    {
      title: ["No. Polis / No. Sertifikat", "No. Kontainer"],
    },
    {
      title: ["Tertanggung", "Rute"],
    },
    {
      title: ["Tanggal Submit", "ETD"],
    },
    {
      title: ["Status", "Premi"],
    },
  ];

  const isdirty = () => {
    const obj = { ...storedValues };

    const keys = Object.keys(obj);

    let dirty = false;
    keys.forEach((key) => {
      if (obj[key] !== "") {
        dirty = true;
      }
    });

    return dirty;
  };

  return (
    <section>
      <Container className="py-5 mt-1">
        <div id="search-form">
          <StyledAccordion title="Search" isDirty={isdirty()}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Cari</Accordion.Header>
              <Accordion.Body>
                <SearchForm
                  inputs={inputs}
                  defaultValues={storedValues}
                  handleSearch={handleSearch}
                  handleReset={handleReset}
                />
              </Accordion.Body>
            </Accordion.Item>
          </StyledAccordion>
        </div>

        <div className="mt-5">
          <DataTable
            active="declaration"
            headers={headers}
            data={dataDeclaration}
            loading={loading}
            error={error}
            totalPage={totalPage}
            onPageChange={handlePageClick}
            page={storedPage}
          />
        </div>
      </Container>
    </section>
  );
};

export default Declaration;

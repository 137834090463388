const initialState = {
  dataSearchDeclaration: {
    storedValues: {
      policyNumber: "",
      certificateNumber: "",
      containerNo: "",
      insuredName: "",
      portDeparture: "",
      portDestination: "",
      startDateClaim: "",
      endDateClaim: "",
      submitFrom: "",
      submitTo: "",
      etdFrom: "",
      etdTo: "",
      minPremium: "",
      maxPremium: "",
      status: "",
    },
    storedPage: 1,
  },
};

const declarationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_SEARCH_DECLARATION":
      return {
        ...state,
        dataSearchDeclaration: action.payload,
      };
    case "RESET_DECLARATION":
      return (state = initialState);
    default:
      return state;
  }
};

export default declarationReducer;
